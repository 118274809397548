import Confetti from 'react-confetti';

import { slideLogo } from 'src/assets/img';

const HeaderContent = ({
  displayConfetti = false,
  displayNavElements = true,
  displayEffectiveDate = true,
}) => {
  return (
    <>
      {displayConfetti && (
        <div className="fixed top-0 left-0 z-50">
          <Confetti
            colors={['#C6BCD0', '#6648DC', '#483499']}
            gravity={0.1}
            recycle={false}
            confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: -30 }}
            initialVelocityY={{ min: -10, max: 4 }}
            initialVelocityX={4}
          />
        </div>
      )}
      <div className="w-full xl:fixed pt-4 px-4 xs:px-6">
        <div className="flex justify-between items-center mx-auto max-w-2xl xl:max-w-none xl:px-6">
          <img src={slideLogo} alt="Slide Logo" className="w-36 -pl-10 mb-4" />
          <div className="flex flex-col xs:flex-row xs:space-x-2 xs:items-center"></div>
        </div>
      </div>
    </>
  );
};

export default HeaderContent;
