module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      borderRadius: {
        '5px': '5px',
      },
      flex: {
        2: '2 2 0%',
        3: '3 3 0%',
      },
      maxHeight: {
        25: '25%',
        50: '50%',
        75: '75%',
      },
      fontSize: {
        '2xs': ['10px', '20px'], // Viewport footer
        '2sm': ['13px', '1.5'],
        'xbase': ['15px', '1.5'],
        '2lg': ['1.167rem', '1.75rem'], // blue headings contrast minimum
        '3xl': ['2rem', '3rem'], // tailwind 30px => custom 32px
        '4xl': ['2.625rem', '1'], // tailwind 36px => custom 42px
        '5xl': ['3.125rem', '1'], // tailwind 48px => custom 50px
      },
      fontFamily: {
        heading: ['Mulish', 'Helvetica', 'Arial', 'sans-serif'],
        content: ['Mulish', 'Helvetica', 'Arial', 'sans-serif'],
      },
      ringOpacity: {
        15: '0.15',
      },
      ringColor: {
        violet: '#E0E7FF',
      },
      stroke: {
        violet: '6648DC',
      },
      colors: {
        // https://chir.ag/projects/name-that-color/
        'violet': {
          lightest: '#6648DC1A',
          lighter: '#6648DC34',
          faded: '#6648DC80',
          light: '#6648DCBF',
          DEFAULT: 'rgba(102, 72, 220, 1)',
        },
        'input': {
          light: '#6B7280',
          lighter: '#B4BCCE',
          background: '#F8F7FD',
          DEFAULT: '#2E2A3A',
        },
        'interview': {
          background: '#F8F7FD',
          background2: '#EAE8F9',
          background3: '#C4C7E8',
          scrollbar: '#C7CAEF',
        },
        'mystic': {
          light: '#DCE2EB7E',
          dark: '#bbc0c7',
          DEFAULT: '#DCE2EB',
        },
        'curious-blue': {
          light: '#DCD7F1',
          dark: '#5949A7',
          DEFAULT: '#6244BB',
        },
        'dove-gray': {
          lightest: '#70707080',
          light: '#707070BF',
          DEFAULT: '#707070',
        },
        'spindle': {
          DEFAULT: 'rgba(232, 228, 250, 1)',
        },
        'aqua-haze': {
          DEFAULT: '#EEF3F5',
        },
        'piper': {
          lighter: '#FCF7F6',
          light: '#FFE7BD',
          DEFAULT: '#C14E24',
        },
        'kournikova': {
          DEFAULT: '#FFDA74',
        },
        'error': {
          dark: '#f5222d',
          light: '#F7E6E6',
          DEFAULT: '#E11D48',
        },
      },
      width: {
        '11.5': '2.625rem',
        '76': '19rem',
        '63px': '63px',
        '103px': '103px',
        '104px': '104px',
        '130px': '130px',
        '141px': '141px',
        '180px': '180px',
        '188px': '188px',
        '211px': '211px',
        '220px': '220px',
        '230px': '220px',
        '232px': '232px',
        '261px': '261px',
        '268px': '268px',
        '302px': '302px',
        '442px': '442px',
        '460px': '460px',
        '644px': '644px',
      },
      height: {
        '10.5': '2.750rem',
        '51px': '51px',
      },
      minWidth: {
        '1': '1rem',
        '1/6': '16.666667%',
        '1/5': '20%',
        '1/4': '25%',
        '1/3': '33.333333%',
        '1/2': '50%',
        '3/4': '75%',
      },
      maxWidth: {
        tiny: '12rem',
      },
      padding: {
        '17px': '17px',
      },
      ringWidth: {
        6: '6px',
      },
      screens: {
        xs: '375px',
        tablet: '700px',
      },
      spacing: {
        '26': '6.5rem',
        '53px': '53px',
      },
      boxShadow: {
        mmd: '0px 4px 4px 0px #00000040',
        card: '0px 0px 4px 0px #00000040',
      },
      backgroundImage: (theme) => ({
        'circle-violet': `url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='rgb(79 70 229)' xmlns='http://www.w3.org/2000/svg'><circle cx='8' cy='8' r='9' /><circle cx='8' cy='8' r='3' fill='white'/></svg>")`,
        'check': `url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>")`,
      }),
    },
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      cursor: ['disabled'],
      textColor: ['disabled'],
      margin: ['last'],
      backgroundImage: ['checked'],
      ringOffsetWidth: ['focus'],
    },
  },
  plugins: [],
};
