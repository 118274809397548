import React from 'react';
import { useHistory } from 'react-router-dom';

import { BasicLayout, Card, Button, Footer } from 'src/common';

const ResumptionError = ({ error, retry }) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push('/');
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card icon="seashells" title="Aw, shucks!" subtitle={error}>
        <div className="flex justify-center bg-white pb-4">
          <Button color="dark" className="w-36 justify-center" onClick={retry}>
            Try Again
          </Button>
        </div>
      </Card>
      <Footer>
        <Footer.Back
          rounded
          hideIcon={true}
          label="Exit"
          onClick={handleGoBack}
          className="w-105px"
        />
      </Footer>
    </BasicLayout>
  );
};

export default ResumptionError;
