import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useFeatureFlags } from 'src/lib/hooks';
import Landing from 'src/views/Landing';
import Login from 'src/views/Login';
import Onboarding from 'src/views/Onboarding';
import Campaign from 'src/views/Campaign';
import Resume from 'src/views/Resume';
import Maintenance from 'src/views/Maintenance';
import Pause from './views/Pause';
import AccountUnblocked from './views/AccountUnblocked';

const App = () => {
  const { enableCampaignRoute, maintenanceMode } = useFeatureFlags();

  if (maintenanceMode) {
    return <Maintenance />;
  }

  return (
    <Switch>
      <Route path="/onboarding" component={Onboarding} />
      {enableCampaignRoute && (
        <Route exact path="/campaign" component={Campaign} />
      )}
      <Route exact path="/login" component={Login} />
      <Route exact path="/resume" component={Resume} />
      <Route exact path="/pause" component={Pause} />
      <Route exact path="/account-unblocked" component={AccountUnblocked} />
      <Route path="/" component={Landing} />
    </Switch>
  );
};

export default App;
