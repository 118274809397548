import React from 'react';
import {
  desktopBGBuildingsLeft,
  desktopBGBuildingsRight,
  desktopBGCloudsLeft,
  desktopBGCloudsRight,
  desktopBGFloor,
} from 'src/assets/img';
import { useFeatureFlags, useModel } from 'src/lib/hooks';
import classnames from 'tailwindcss-classnames';
import AnnouncementBar from '../AnnouncementBar';
import { links } from 'src/lib/enums';

const Viewport = ({ children }) => {
  const { showMaintenanceTimeWarning, maintenanceMode } = useFeatureFlags();
  const { BindingRestriction } = useModel.quote();

  const getBgClasses = () => {
    const noAnnouncement = !BindingRestriction && !showMaintenanceTimeWarning;
    const hasBinding = BindingRestriction && !showMaintenanceTimeWarning;
    const hasMaintenance = !BindingRestriction && showMaintenanceTimeWarning;
    const hasBoth = BindingRestriction && showMaintenanceTimeWarning;
    return {
      '-bottom-0.5': maintenanceMode || noAnnouncement,
      'bottom-8': !maintenanceMode && (hasBinding || hasMaintenance),
      'bottom-24 md:bottom-16': !maintenanceMode && hasBoth,
    };
  };

  const BgClouds = ({ className, fixedHeight }) => (
    <div
      className={classnames('absolute top-16 w-full h-full', className)}
      style={{
        background: [
          `url("${desktopBGCloudsLeft}") no-repeat top left/auto ${fixedHeight}px`,
          `url("${desktopBGCloudsRight}") no-repeat top right/auto ${fixedHeight}px`,
          'linear-gradient(359deg, #FFF 29.99%, #F8F7FD 54.34%, #E8E4FA 87.15%)',
        ].join(','),
      }}
    />
  );

  const BgGround = ({ className, fixedHeight }) => (
    <div
      className={classnames('h-32 absolute w-full', getBgClasses(), className)}
      style={{
        background: [
          `url("${desktopBGFloor}") repeat-x bottom/auto ${fixedHeight}px`,
          `url("${desktopBGBuildingsRight}") no-repeat bottom right/auto ${fixedHeight}px`,
          `url("${desktopBGBuildingsLeft}") no-repeat bottom left/auto ${fixedHeight}px`,
          'linear-gradient(359deg, #FFF , #FFF )',
        ].join(','),
      }}
    />
  );

  return (
    <div className="flex flex-col min-h-screen relative bg-spindle overflow-hidden xl:h-screen">
      <BgClouds className="hidden lg:block" fixedHeight={400} />
      <BgGround className="hidden lg:block xl:hidden mb-16" fixedHeight={80} />
      <BgGround className="hidden xl:block mb-16" fixedHeight={128} />

      <div
        id="scrolling-container"
        className="xl:overflow-auto xl:relative h-full"
      >
        <header id="viewport-header" />
        <main className="flex-auto px-4 pt-0 xs:px-6 xl:pt-6 pb-20 md:pb-10">
          {children}
        </main>
        <footer className="fixed bottom-0 w-full">
          <div>
            {!maintenanceMode && BindingRestriction && (
              <AnnouncementBar
                role="alert"
                className="bg-kournikova text-violet text-sm font-content"
                text={BindingRestriction.Message}
                showLink={true}
                textLink="Learn More"
                href="https://www.slideinsurance.com/binding-restriction"
                classNameLink="ml-1 underline "
              />
            )}
            {!maintenanceMode && showMaintenanceTimeWarning && (
              <AnnouncementBar
                role="alert"
                className="bg-yellow-500 text-violet text-sm"
                text="Our systems will begin routine maintenance within the next 10 minutes.
                You can resume your quote where you left off after the break."
              />
            )}
          </div>
          <div className="hidden lg:block fixed bottom-6 w-full flex-row pt-8 ml-8">
            <div className="text-left text-violet  text-2xs font-bold flex gap-3">
              ©2023, Slide Insurance Holdings. All rights reserved.{' '}
              <a
                href={links.privacyPolicy}
                target="_blank"
                rel="noreferrer"
                className="text-left text-violet  text-2xs font-bold underline initial-flex"
              >
                Privacy Policy
              </a>{' '}
              <a
                href={links.termsOfUse}
                target="_blank"
                rel="noreferrer"
                className="text-left text-violet  text-2xs font-bold underline initial-flex"
              >
                Terms of Use
              </a>{' '}
            </div>
          </div>
        </footer>
        <section id="viewport-loading-content" />
      </div>
    </div>
  );
};
export default Viewport;
