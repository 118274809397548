/* eslint-disable no-console */
import axios from 'axios';
const axiosErrorHandler = (error) => {
  if (error === null) {
    throw new Error('Unrecoverable error!! Error is null!');
  }
  if (axios.isAxiosError(error)) {
    if (error.code === 'ERR_NETWORK') {
      console.log('connection problems..');
    } else if (error.code === 'ERR_CANCELED') {
      console.log('connection canceled..');
    }
  }

  //Something happened in setting up the request and triggered an Error
  console.log(error.message);
};
const addErrorHandler = (resp) => {
  if (resp) {
    return resp.catch(axiosErrorHandler);
  }

  return resp;
};
export default addErrorHandler;
