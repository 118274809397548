import merge from 'lodash.merge';
import axios from 'axios';

import { api } from 'src/lib/hooks/useApi/useApi';
import { windMitTransform } from 'src/lib/transforms';
import { ERROR_TYPES } from 'src/products/florida/ho6/constants';

const quote = {
  state: {},
  reducers: {
    'setQuote': (state, updatedQuote) => merge({}, state, updatedQuote),
    'global/reset': (state) => ({
      FirstName: '',
      LastName: '',
      Property: '',
    }),
  },
  effects: (dispatch) => ({
    resume: async ({ ID, PolicyNumber, ResumeFrom }) => {
      const { data } = await api.secure.get(`/quote/${ID}`);
      const { FirstName, LastName } = data.Customer;

      const WindMitigationFormData = windMitTransform.input(
        data.WindMitigationFormData
      );

      if (PolicyNumber) {
        dispatch.session.setSession({
          policy: {
            policyNumber: PolicyNumber,
          },
        });
      }

      const quote = {
        ...data,
        WindMitigationFormData,
        FirstName,
        LastName,
        State: 'FL',
        FormType: 'HO-6',
        ID,
      };
      dispatch.quote.setQuote(quote);
    },

    newQuote: async (initialQuote) => {
      const { data } = await api.secure.post('/quote', initialQuote);
      if (
        data.Status === ERROR_TYPES.REFERRED_TO_INT_AGENT ||
        data.Status === ERROR_TYPES.REFERRED_TO_EXT_AGENT
      ) {
        const error = new Error(data.Status);
        error.statusCode = 422;
        error.errorType = data.Status;
        error.message = data.Reason;
        error.name = 'Error';
        throw error;
      } else {
        const { Id, QuoteNumber, Premium, MissingInfo } = data;

        const quote = {
          ID: Id,
          Premium,
          MissingInfo,
          QuoteNumber,
          ...initialQuote,
        };
        await dispatch.quote.setQuote(quote);

        return quote;
      }
    },

    upsertMissingInfo: async ({ MissingInfo, Interview, Session }, root) => {
      const { ID } = root.quote;
      const { data } = await api.secure.patch(`/quote/${ID}/missing-info`, {
        ID,
        AssociationId: root.user.email,
        MissingInfo,
        Interview,
        ...Session,
      });

      if (
        data.Status === ERROR_TYPES.REFERRED_TO_EXT_AGENT ||
        data.Status === ERROR_TYPES.REFERRED_TO_INT_AGENT
      ) {
        const error = new Error(data.Status);
        error.statusCode = 422;
        error.errorType = data.Status;
        error.message = data.Reason;
        error.name = 'Error';
        throw error;
      } else {
        const Premium = data.Premium;

        await dispatch.quote.setQuote({ Premium });
      }
    },

    updateQuote: async (updates, root) => {
      const { ID } = root.quote;
      const { data: currentQuote } = await api.secure.patch(
        `/quote/${ID}`,
        updates
      );

      if (
        currentQuote.Status === ERROR_TYPES.REFERRED_TO_INT_AGENT ||
        currentQuote.Status === ERROR_TYPES.REFERRED_TO_EXT_AGENT
      ) {
        const error = new Error(currentQuote.Status);
        error.statusCode = 422;
        error.errorType = currentQuote.Status;
        error.message = currentQuote.Reason;
        error.name = 'Error';
        throw error;
      } else {
        const WindMitigationFormData = windMitTransform.input(
          currentQuote.WindMitigationFormData
        );

        dispatch.quote.setQuote({ ...currentQuote, ...WindMitigationFormData });
      }
    },

    updateCoveragesAndDeductibles: async (quote, root) => {
      const { ID } = root.quote;

      const coveragesResponse = await api.secure.put(`/quote/${ID}/coverages`, {
        Coverages: quote.Coverages,
        Deductibles: quote.Deductibles,
      });

      if (
        coveragesResponse.Status === ERROR_TYPES.REFERRED_TO_INT_AGENT ||
        coveragesResponse.Status === ERROR_TYPES.REFERRED_TO_EXT_AGENT
      ) {
        const error = new Error(coveragesResponse.Status);
        error.statusCode = 422;
        error.errorType = coveragesResponse.Status;
        error.message = coveragesResponse.Reason;
        error.name = 'Error';
        throw error;
      }

      const optionalCoveragesResponse = await api.secure.put(
        `/quote/${ID}/optional-coverages`,
        {
          Coverages: quote.OptionalCoverages,
        }
      );

      if (
        optionalCoveragesResponse.Status ===
          ERROR_TYPES.REFERRED_TO_INT_AGENT ||
        optionalCoveragesResponse.Status === ERROR_TYPES.REFERRED_TO_EXT_AGENT
      ) {
        const error = new Error(optionalCoveragesResponse.Status);
        error.statusCode = 422;
        error.errorType = optionalCoveragesResponse.Status;
        error.message = optionalCoveragesResponse.Reason;
        error.name = 'Error';
        throw error;
      }

      const { Premium, Deductibles, Coverages } = optionalCoveragesResponse;

      dispatch.quote.setQuote({
        Premium,
        Coverages,
        Deductibles,
      });
    },

    upsertWindMitQuestionnaire: async ({ FormType, Questionnaire }, root) => {
      const { ID } = root.quote;
      const WindMitigationFormData = { ...root.quote.WindMitigationFormData };
      WindMitigationFormData[FormType] = {
        ...WindMitigationFormData[FormType],
        Questionnaire,
      };

      const body = windMitTransform.output(WindMitigationFormData);

      const {
        data: { TotalDiscounts },
      } = await api.secure.put(`/quote/${ID}/wind-mitigation`, body);

      dispatch.quote.setQuote({
        WindMitigationFormData,
        TotalDiscounts,
      });
    },
    uploadWindMitForm: async ({ FormType, InspectionDate, File }, root) => {
      const { ID } = root.quote;

      const WindMitigationFormData = { ...root.quote.WindMitigationFormData };
      WindMitigationFormData[FormType] = {
        ...WindMitigationFormData[FormType],
        Form: { FormType, InspectionDate },
      };

      if (File) {
        WindMitigationFormData[FormType].Form.FileName = File.name;
        const { data } = await api.secure.post(
          `/quote/${ID}/wind-mitigation/${FormType}`,
          {
            FileName: File.name,
            InspectionDate,
          }
        );

        await axios.put(data.FileUrl, File, {
          headers: {
            'Content-Type': 'application/octet-stream',
            'x-ms-blob-type': 'BlockBlob',
          },
        });
      }

      dispatch.quote.setQuote({ WindMitigationFormData });
    },
    upsertAdditionalInterests: async (payload, root) => {
      const { ID } = root.quote;

      const { data } = await api.secure.put(
        `/quote/${ID}/additional-interest`,
        payload
      );

      dispatch.quote.setQuote({ ...root.quote, ...data });
    },

    fetchQuote: async (_, root) => {
      const { ID } = root.quote;
      const { data } = await api.secure.get(`/quote/${ID}`);

      const WindMitigationFormData = windMitTransform.input(
        data.WindMitigationFormData
      );

      const quote = {
        ...data,
        WindMitigationFormData,
      };
      await dispatch.quote.setQuote(quote);

      return quote;
    },

    excludeWindMitForms: async ({ FormType }, root) => {
      const { ID } = root.quote;
      const WindMitigationFormData = { ...root.quote.WindMitigationFormData };

      if (WindMitigationFormData[FormType]) {
        WindMitigationFormData[FormType] = null;
        const body = windMitTransform.output(WindMitigationFormData);

        await api.secure.put(`/quote/${ID}/wind-mitigation`, body);

        dispatch.quote.setQuote({ WindMitigationFormData });
      }
    },

    makePaymentAndIssue: async (body, { quote }) => {
      const { ID } = quote;
      const { data } = await api.secure.post(
        `/quote/${ID}/pay-and-issue`,
        body
      );

      dispatch.session.setSession({
        policy: {
          policyNumber: data.policyNumber,
          confirmationNumber: data.data.clientReferenceInformation.code,
          eSignatureLink: data.eSignatureLink,
        },
      });

      await dispatch.quote.fetchQuote();
    },

    getPaymentPlans: async (body, { quote: { ID } }) => {
      const { data } = await api.secure.get(`/quote/${ID}/payment-plan`, body);

      dispatch.quote.setQuote({
        PaymentPlans: {
          Available: data.AvailablePlans,
          Selected: data.SelectedPlan,
        },
      });
    },

    downloadAttachment: async ({ ID, attachment }) => {
      const file = await api.secure.get(
        `/quote/${ID}/attachments/${attachment.Id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
          },
          responseType: 'blob',
        }
      );
      const blob = new Blob([file.data], { type: 'application/pdf' });

      // Old MS Edge don't allow using a blob object directly as link href
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        const objUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objUrl;
        link.download = `${attachment.Title}.pdf`;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          window.URL.revokeObjectURL(objUrl);
        }, 250);
      }
    },
  }),
};

export default quote;
