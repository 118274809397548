import React, { useEffect, useState } from 'react';
import { classnames } from 'tailwindcss-classnames';

import { useModel } from 'src/lib/hooks';
import { PAGES, WIND_MIT } from '../constants';

const FormSkipButton = ({
  FormType,
  nextPage,
  isCompleted,
  isFooter = true,
  className,
}) => {
  const forms = {
    uniformQuestionnairePage: PAGES.UniformQuestionnairePage,
    uniformUploadPage: PAGES.UniformUploadPage,
  };

  const { skipList } = useModel.session();
  const { excludeWindMitForms } = useModel.quote.dispatch();
  const { skip } = useModel.session.dispatch();

  const [{ loading }, setState] = useState({
    loading: false,
    runEligibility: false,
  });

  const onClick = async () => {
    switch (FormType) {
      case WIND_MIT.UNIFORM:
        skip({
          [forms.uniformQuestionnairePage]: true,
          [forms.uniformUploadPage]: true,
        });
        break;
      default:
        break;
    }

    const pageSet = new Set(Object.values(forms));
    const numFormsSkipped =
      skipList.filter((page) => pageSet.has(page)).length / 2 + 1;
    const skipAllForms = numFormsSkipped === 3;

    skip({
      [PAGES.SavingsPage]: skipAllForms,
    });

    setState({
      loading: true,
      runEligibility: skipAllForms,
    });
  };

  useEffect(() => {
    const onSkip = async () => {
      await excludeWindMitForms({ FormType });
      await nextPage({ waitProfileUpdate: true });
    };

    if (loading) {
      onSkip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeWindMitForms, FormType, nextPage, loading]);
  if (!isFooter) {
    return (
      <button className={classnames('underline')} onClick={onClick}>
        skip this discount
      </button>
    );
  }

  return (
    <span
      role="button"
      className={classnames('text-2sm text-violet underline', className)}
      onClick={onClick}
      color="blue-light"
      variant="small"
      loading={loading}
      aria-hidden
    >
      {isCompleted ? 'Remove This Form' : 'Skip This Form for Now'}
    </span>
  );
};

export default FormSkipButton;
