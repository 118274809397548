import { useEffect } from 'react';
import { useState } from 'react';
import { useController } from 'react-hook-form';
const useFile = ({ name, control, rules, defaultValue }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const { field } = useController({ name, control, rules, defaultValue });

  const { value, onChange, ref } = field;
  useEffect(() => {
    onChange(defaultValue);
    setFile(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!value) {
      setFile(null);
      ref.current.value = null;
    }
  }, [value, ref]);

  const openFileBrowser = () => {
    ref.current.click();
  };
  const removeSelectedFile = () => {
    setError(null);
    setFile(null);
    ref.current.value = '';
  };

  return {
    file,
    setFile,
    error,
    setError,
    field,
    openFileBrowser,
    removeSelectedFile,
  };
};

export default useFile;
