import { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import FocusTrap from 'focus-trap-react';
import { classnames } from 'tailwindcss-classnames';

import { Icon } from 'src/common';
import './Tooltip.css';
import useUId from 'src/lib/hooks/useUId';

const TooltipPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.querySelector('body'));

const Tooltip = ({
  icon = 'infoCircle',
  title,
  text,
  children,
  className,
  place = 'bottom',
  size,
  color = 'curious-blue',
  hideTitle = false,
}) => {
  const labelId = useUId();
  const tooltipId = useUId();
  const btnRef = useRef(null);
  const tooltipRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (open) => {
    setOpen(open);
  };

  const handleClose = () => {
    if (tooltipRef && tooltipRef.current) {
      // Why this? https://github.com/wwayne/react-tooltip/issues/449
      tooltipRef.current.tooltipRef = null;
    }
    ReactTooltip.hide();

    if (btnRef && btnRef.current) {
      btnRef.current.focus();
    }
  };

  useEffect(() => {
    const onKeyup = (e) => {
      if (open && e.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [open]);

  return (
    <div className={className}>
      <button
        type="button"
        data-event="click"
        data-for={tooltipId}
        data-tip={tooltipId}
        className={classnames(
          'flex items-center text-curious-blue-dark focus:outline-none focus:ring-4 focus:ring-curious-blue-light',
          {
            'rounded-full': icon === 'infoCircle' && !children,
          }
        )}
        aria-label={title || text}
        aria-controls={tooltipId}
        title={title || text}
        ref={btnRef}
      >
        {children}
        <Icon name={icon} color={color} size={size} />
      </button>
      <TooltipPortal>
        <FocusTrap active={open}>
          <div>
            <ReactTooltip
              id={tooltipId}
              place={place}
              effect="solid"
              event="click"
              arrowColor="#fff"
              multiline={true}
              clickable
              afterShow={() => handleOpen(true)}
              afterHide={() => handleOpen(false)}
              ref={tooltipRef}
              role="dialog"
              aria-modal="true"
              aria-labelledby={labelId}
            >
              <div className="py-8 w-302px px-6 text-left rounded-lg bg-white mx-5 sm:mx-0 relative outline-none">
                <button
                  type="button"
                  className="absolute right-0 top-0 m-2.5 p-0.5 "
                  onClick={handleClose}
                >
                  <Icon name="cancel" size="2sm" />
                  <span className="sr-only">Close</span>
                </button>
                <h1
                  className={classnames(
                    'text-lg text-violet font-bold font-content leading-tight',
                    { 'sr-only': hideTitle }
                  )}
                  id={labelId}
                >
                  {title}
                </h1>
                {typeof text === 'string' ? (
                  <p className="mt-2 text-violet text-2sm font-normal">
                    {text}
                  </p>
                ) : (
                  text
                )}
              </div>
            </ReactTooltip>
            <button
              tabIndex={-1}
              aria-hidden="true"
              onClick={handleClose}
              className={classnames(
                'fixed inset-0 w-screen h-screen bg-mystic z-40 transition outline-none cursor-default',
                {
                  'visible opacity-50': open,
                  'invisible opacity-0 ': !open,
                }
              )}
            />
          </div>
        </FocusTrap>
      </TooltipPortal>
    </div>
  );
};

export default Tooltip;
