import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { validateBirthDate, validateEmail } from 'src/lib/validations';
import suffixes from 'src/lib/suffixes';
import {
  BasicLayout,
  Card,
  Error,
  Footer,
  Form,
  Icon,
  Input,
  AutocompleteAddress,
  Select,
  MaskedInput,
  DateInput,
} from 'src/common';
import { useAuth0, useModel, useForm, useFeatureFlags } from 'src/lib/hooks';
import products from 'src/products';
import { links } from 'src/lib/enums';
import { PRODUCTS } from 'src/products/products';
import { useDispatch } from 'react-redux';

const Landing = () => {
  const history = useHistory();
  const { pendingChanges: defaultValues, policy } = useModel.session();
  const { setPendingChanges } = useModel.session.dispatch();
  const form = useForm({
    defaultValues,
    onError: async ({ err, setFormError, defaultOnError }) => {
      await defaultOnError();
    },
  });
  const {
    control,
    handleSubmit,
    errors,
    formError,
    formState: { isSubmitting },
    register,
  } = form;
  const dispatch = useDispatch();
  const { setQuote } = useModel.quote.dispatch();
  const { logout } = useAuth0();
  const { enableExplicitResumption, enableCampaignRoute } = useFeatureFlags();
  const { Property, FirstName, LastName } = useModel.quote();

  const selectedProduct = PRODUCTS.FLORIDA_H06;

  const reset = () => {
    dispatch({ type: 'global/reset' });
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    logout();
    data.FirstName = data.FirstName.trim();
    data.LastName = data.LastName.trim();
    products?.[selectedProduct.FullState.toLowerCase()]?.[
      selectedProduct.FormType.replace('-', '').toLowerCase()
    ]?.preload?.();
    const initialQuote = {
      State: selectedProduct.State,
      FormType: selectedProduct.FormType,
      WritingCompany: 'HomeFront Insurance',
      FirstName: data.FirstName,
      LastName: data.LastName,
      Suffix: data.Suffix,
      Email: data.Email,
      DateOfBirth: data.DateOfBirth,
      Phone: data.Phone,
      Property: data.Property,
      ResumeFrom: 'CondoStoriesPage',
      SkipList: [],
      FurthestReach: 'CondoStoriesPage',
    };
    setPendingChanges(initialQuote);
    setQuote(initialQuote);
    history.push('/onboarding');
  });

  const suffixOptions = suffixes.map((s) => ({
    id: s.value,
    title: s.name,
  }));

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        // icon="welcome"
        title="Welcome!"
        subtitle="We think buying insurance should be a breeze. Tell us a little about yourself and get your estimated premium instantly."
      >
        {formError && <Error>{formError}</Error>}
        <Form
          id="address-select-form"
          onSubmit={onSubmit}
          className="space-y-4"
        >
          <div className="flex flex-col items-center text-center mb-5 mx-auto">
            <Icon name="condo" size={'3xl'} className="xs:pr-4 pr-0" />
            <p className="text-violet font-bold">
              {' '}
              Let’s start with some info about your home and you.
            </p>
          </div>
          <AutocompleteAddress
            containerClassName="flex-1"
            label="Property"
            name="Property.Location"
            control={control}
            error={errors?.Property?.Location?.message}
            international={false}
            column={false}
            rules={{
              required: 'Address is required.',
            }}
            {...form}
          />
          <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 mt-2">
            <Input
              containerClassName="flex-1"
              label="First Name"
              name="FirstName"
              error={errors?.FirstName?.message}
              defaultValue={FirstName}
              ref={register({
                required: 'First name is required.',
                validate: (value) =>
                  value.trim().length > 0 || 'First name is required.',
              })}
              autoComplete="given-name"
            />
            <Input
              containerClassName="flex-1"
              label="Last Name"
              name="LastName"
              error={errors?.LastName?.message}
              defaultValue={LastName}
              ref={register({
                required: 'Last name is required.',
                validate: (value) =>
                  value.trim().length > 0 || 'Last name is required.',
              })}
              autoComplete="family-name"
            />
            <div className="w-24">
              <Select
                label="Suffix"
                name="Suffix"
                placeholder="Suffix"
                defaultValue=""
                error={errors?.Suffix?.message}
                options={suffixOptions}
                control={control}
                required={false}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 mt-2">
            <DateInput
              containerClassName="flex-1"
              label="Date of Birth"
              name="DateOfBirth"
              error={errors?.DateOfBirth?.message}
              control={control}
              rules={{
                required: 'Date of Birth is required',
                validate: validateBirthDate,
              }}
              autoComplete="bday"
            />
            <MaskedInput
              containerClassName="flex-1"
              label="Phone"
              name="Phone"
              error={errors?.Phone?.message}
              mask="(999) 999-9999"
              placeholder="(000) 000-0000"
              rules={{
                required: { value: true, message: 'Phone number is required.' },
                pattern: {
                  value: /\(\d{3}\) \d{3}-\d{4}/,
                  message: 'Invalid phone number.',
                },
              }}
              control={control}
              autoComplete="tel-national"
            />
          </div>
          <Input
            containerClassName="flex flex-col flex-grow"
            label="Email"
            name="Email"
            error={errors?.Email?.message}
            placeholder="name@domain.com"
            ref={register({
              required: { value: true, message: 'Email is required.' },
              validate: validateEmail,
            })}
            autoComplete="email"
          />
        </Form>
        {((Property && !policy?.confirmationNumber) ||
          enableExplicitResumption) && (
          <div className="flex flex-row justify-center mt-4">
            <span className="text-sm">Already started a quote?</span>
            <Link to="/resume" className="underline text-violet text-sm pl-1">
              Get back to it!
            </Link>
          </div>
        )}
        {enableCampaignRoute && (
          <div className="flex flex-col items-center py-3">
            <Link to="/campaign" className="mt-1 underline text-center text-sm">
              Received an invitation code? Enter here
            </Link>
          </div>
        )}
        <div className="flex max-w mx-auto mt-6 text-center text-2xs font-normal text-input-light ">
          <span>
            By providing your email and selecting “Get Started”, you agree to
            our{' '}
            <a
              href={links.privacyPolicy}
              target="_blank"
              rel="noreferrer"
              className="text-violet underline"
            >
              Privacy Policy.
            </a>{' '}
            You also consent to receiving electronic communications, and using
            an e-signature. See our{' '}
            <a
              href={links.termsOfUse}
              target="_blank"
              rel="noreferrer"
              className="text-violet underline"
            >
              Terms of Use
            </a>{' '}
            for more information.
          </span>
        </div>
      </Card>
      <Footer displayAssitance={false}>
        <Footer.Next
          onClick={onSubmit}
          rounded
          hideIcon={true}
          label="Get Started"
          loading={isSubmitting}
        />
      </Footer>
    </BasicLayout>
  );
};

export default Landing;
