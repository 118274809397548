import classNames from 'tailwindcss-classnames';
import Icon from 'src/common/Icon';
import Button from '../Button';
import FormSkipButton from 'src/products/florida/ho6/discounts/FormSkipButton';
import useFile from 'src/lib/hooks/useFile';
const FileInput = ({
  btnText = 'Upload form',
  control,
  name,
  defaultValue,
  rules,
  buttonClassName,
  icon = 'arrowUp',
  FormType,
  nextPage,
  error: validationError,
}) => {
  const { error, file, setError, setFile, field, openFileBrowser } = useFile({
    name,
    control,
    rules,
    defaultValue,
  });
  const { onChange, ref } = field;

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setError(null);
      setFile(file);

      if (file.name.split('.').pop() !== 'pdf') {
        setError(
          <>
            This file type is not supported. Please upload a <br /> PDF, or{' '}
            <FormSkipButton
              isFooter={false}
              FormType={FormType}
              nextPage={nextPage}
            />
            .
          </>
        );
        onChange(undefined);
      } else {
        onChange(file);
      }
    }
  };

  return (
    <div className="flex flex-col space-y-5 items-center">
      {!file && (
        <>
          <label htmlFor={name} className="sr-only">
            {btnText}
          </label>
          <Button
            onClick={openFileBrowser}
            color="dark"
            variant="rectangular"
            iconLeft={icon}
            type="button"
            {...(error && {
              'aria-describedby': `${name}-error`,
            })}
            className={buttonClassName}
          >
            {btnText}
          </Button>
          {validationError && (
            <span className="text-error text-sm">Form upload is required.</span>
          )}
        </>
      )}
      {file && (
        <div className="flex space-x-4">
          <Icon
            name="file"
            size="lg"
            className="mt-3"
            color={error ? 'error' : 'violet'}
          />
          <div className="flex flex-col space-y-1.5 text-violet text-sm max-w-tiny sm:max-w-xs">
            <label
              htmlFor={name}
              className={classNames(
                'font-bold truncate',
                error && 'text-error'
              )}
            >
              {file.name}
            </label>
            {error && (
              <p id={`${name}-error`} className="text-error">
                {error}
              </p>
            )}
            <button
              type="button"
              onClick={openFileBrowser}
              className={classNames(
                'underline text-left',
                error && 'text-error'
              )}
            >
              Replace File
            </button>
          </div>
        </div>
      )}
      <input
        id={name}
        ref={ref}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        accept="application/pdf"
      />
    </div>
  );
};

export default FileInput;
