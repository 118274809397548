import { twMerge } from 'src/lib/mergeTailwind';

const DetailItem = ({
  title,
  children,
  titleClassName,
  textClassName,
  fontBold = true,
}) => (
  <>
    <dt
      className={twMerge(
        'text-violet text-sm',
        fontBold ? 'font-bold' : 'font-light',
        titleClassName
      )}
    >
      {title}
    </dt>
    <dd className={twMerge('text-input leading-4 text-sm pb-3', textClassName)}>
      {children}
    </dd>
  </>
);

export default DetailItem;
