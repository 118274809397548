import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import classnames from 'tailwindcss-classnames';

import { useResponsiveVariant } from 'src/lib/hooks';
import Icon from 'src/common/Icon';
import { Fieldset } from '..';

const RadioInput = ({
  name,
  label,
  labelSrOnly = false,
  control,
  defaultValue,
  fieldsetVariant = 'default',
  containerClassName,
  radioInputClassName,
  rules,
  ariaControls,
  children,
  layout = 'vertical',
  fullWidth,
  disabled = false,
  error,
}) => {
  const responsiveLayout = useResponsiveVariant(layout);
  let effectiveLayout;
  if (layout === 'custom') {
    effectiveLayout = 'custom';
  } else {
    effectiveLayout = responsiveLayout;
  }

  // radio uses 'false' as a value, use validate instead required
  if (rules?.required) {
    let customError = rules.required.message ?? rules.required;
    let customValidate = rules.validate;

    rules.validate = (v) => {
      if (v !== undefined) {
        return customValidate ? customValidate(v) : true;
      }
      return customError;
    };

    rules.required = false;
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value, ref }) => (
        <Fieldset
          label={label}
          labelId={name + '_legend'}
          labelSrOnly={labelSrOnly}
          variant={fieldsetVariant}
          error={error}
          disabled={disabled}
        >
          <div
            className={classnames(
              'flex',
              containerClassName ?? 'flex-col items-center justify-center'
            )}
          >
            <div
              className={classnames(
                'flex flex-wrap',
                {
                  'items-end justify-around w-full mt-3':
                    effectiveLayout === 'vertical',
                  'flex-col items-start space-y-4 mt-5':
                    effectiveLayout === 'horizontal',
                  'items-center p-2 w-full max-w-full':
                    effectiveLayout === 'inline',
                  'justify-around w-full mt-3': effectiveLayout === 'custom',
                },
                effectiveLayout === 'vertical' &&
                  (radioInputClassName ?? 'space-y-8 sm:space-y-0 space-x-2'),
                effectiveLayout === 'custom' &&
                  (radioInputClassName ?? 'space-y-2 sm:space-y-0 space-x-2'),
                fullWidth && 'max-w-full'
              )}
            >
              {React.Children.map(
                children,
                (child, i) =>
                  child &&
                  React.cloneElement(child, {
                    name,
                    id: name + i,
                    ariaControls,
                    onChange,
                    selectedValue: value,
                    layout: effectiveLayout,
                    error: error,
                    inputRef: i === 0 && ref,
                  })
              )}
            </div>
          </div>
        </Fieldset>
      )}
    />
  );
};

const Option = ({
  label,
  value,
  disabled,
  name,
  id,
  ariaControls,
  onChange,
  className,
  selectedValue,
  icon,
  iconSize = '4xl',
  subtitle,
  layout,
  error,
  inputRef,
}) => {
  useEffect(() => {
    if (disabled && value === selectedValue) {
      onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const isChecked = value === selectedValue;

  return (
    <label
      htmlFor={id}
      className={classnames(
        'flex flex-col',
        disabled ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer',
        {
          'items-center': layout === 'vertical' || layout === 'horizontal',
          'items-start pl-3 py-2 min-w-1/3 sm:min-w-1/6': layout === 'inline',
          'w-130px overflow-hidden items-center': layout === 'custom',
        },
        className
      )}
    >
      {icon && <Icon name={icon} size={iconSize} className="my-3 h-auto" />}
      {disabled && (
        <Icon
          name="slash"
          size="md"
          className="absolute -mt-0.5 xs:-ml-0.5 xs:-mt-0 lg:-ml-0.5 "
        />
      )}
      <div
        className={classnames('flex items-center justify-center', {
          'flex-col': layout === 'vertical' || layout === 'custom',
        })}
      >
        <input
          className={classnames(
            'appearance-none h-4 w-4 min-w-1 border border-violet rounded-full',
            'focus:ring-2 ring-violet ring-offset-2',
            'checked:bg-circle-violet bg-contain bg-center bg-no-repeat',
            {
              'border border-error rounded-full ring-error-light': !!error,
            }
          )}
          id={id}
          name={name}
          disabled={disabled}
          onClick={() => onChange(value)}
          type="radio"
          value={value}
          defaultChecked={isChecked}
          aria-controls={ariaControls}
          aria-checked={isChecked ? 'true' : 'false'}
          data-testid={id}
          {...(inputRef && { ref: inputRef })}
        />
        <span
          className={classnames('leading-5', {
            'text-violet': !error && layout !== 'interview',
            'text-black font-bold': !error && layout === 'interview',
            'text-error': error,
            'flex flex-col items-center pt-1.5':
              layout === 'vertical' || layout === 'custom',
            'pl-2': layout === 'inline' || layout === 'horizontal',
            'text-xs': layout === 'inline',
            'px-2': layout === 'interview',
          })}
        >
          <span
            className={classnames(
              layout === 'inline' ? isChecked && 'font-bold' : 'font-bold',
              layout === 'custom' ? 'text-center' : ''
            )}
          >
            {label}
          </span>
          {subtitle && (
            <span
              className={classnames('leading-5', {
                'pl-1.5': layout === 'inline' || layout === 'horizontal',
              })}
            >
              {subtitle}
            </span>
          )}
        </span>
      </div>
    </label>
  );
};

RadioInput.Option = Option;

export default RadioInput;
