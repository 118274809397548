import React, { useState } from 'react';
import classNames from 'tailwindcss-classnames';
import Tooltip from '../Tooltip';
const Input = React.forwardRef(
  (
    {
      name,
      id,
      type,
      label,
      error,
      containerClassName,
      className,
      maskedFocus = false,
      optional = false,
      textClass = 'text-xs',
      heightClass = 'h-11',
      onBlur = () => {},
      helpTextTitle,
      helpTextDescription,
      ...props
    },
    ref
  ) => {
    const inputType = type === 'dollar' ? 'number' : type;
    const [focus, setFocus] = useState(false);

    return (
      <div className={containerClassName}>
        <div className="flex flex-col relative">
          {label && (
            <label
              htmlFor={id ?? name}
              className={classNames(
                'font-bold absolute left-4',
                helpTextTitle || helpTextDescription ? '-mt-3' : '-mt-2',
                textClass,
                error
                  ? 'text-error'
                  : focus || maskedFocus
                  ? 'text-violet'
                  : 'text-input-light'
              )}
            >
              <span className="bg-white px-1">
                {label}
                {!!(helpTextTitle || helpTextDescription) && (
                  <Tooltip
                    className="ml-1 inline-block"
                    place="top"
                    title={helpTextTitle}
                    text={helpTextDescription}
                  ></Tooltip>
                )}
              </span>
            </label>
          )}
          <div className="flex items-center">
            {type === 'dollar' && (
              <div className="text-sm absolute left-2">$</div>
            )}
            <input
              className={classNames(
                'w-full px-4 text-input border rounded-lg',
                heightClass,
                textClass,
                error
                  ? 'border-error ring-error-light'
                  : 'border-input-lighter ring-input-light-faded focus:border-violet ring-violet-faded',
                type === 'dollar' && 'pl-5',
                className
              )}
              required={!optional}
              ref={ref}
              aria-invalid={error ? 'true' : 'false'}
              {...(error && { 'aria-describedby': `${id ?? name}-error` })}
              type={inputType ?? 'text'}
              id={id ?? name}
              name={name}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
                onBlur();
              }}
              {...props}
            />
          </div>
          {error && (
            <span
              id={`${id ?? name}-error`}
              className="text-error text-xs mt-1 ml-4"
            >
              {error}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
