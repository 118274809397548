import axios from 'axios';

import config from 'src/config';
import addErrorHandler from 'src/lib/addErrorHandler';
import store from 'src/lib/store';
const getHeaders = (options = {}, secure = false) => {
  const headers = { ...options.headers };

  if (config.slide.locked === 'true') {
    headers['client_id'] = config.slide.clientId;
    headers['client_secret'] = config.slide.clientSecret;
  }

  if (secure) {
    const token = store.getState().user?.token;
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};

const mockApi = {
  putProfileMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
  patchProfileMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
  postInterestMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
};

const api = {
  get: (path, options = {}) =>
    addErrorHandler(
      axios.get(config.slide.baseUrl + path, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  post: (path, body, options = {}) =>
    addErrorHandler(
      axios.post(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  put: (path, body, options = {}) =>
    addErrorHandler(
      axios.put(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  patch: (path, body, options = {}) =>
    addErrorHandler(
      axios.patch(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  delete: (path, options = {}) =>
    addErrorHandler(
      axios.delete(config.slide.baseUrl + path, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  ...mockApi,
};

api.secure = {
  get: (path, options = {}) =>
    addErrorHandler(
      api.get('/secure' + path, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  post: (path, body, options = {}) =>
    addErrorHandler(
      api.post('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  put: (path, body, options = {}) =>
    addErrorHandler(
      api.put('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  patch: (path, body, options = {}) =>
    addErrorHandler(
      api.patch('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  delete: (path, options = {}) =>
    addErrorHandler(
      api.delete('/secure' + path, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  ...mockApi,
};

api.fakeLoad = (data) =>
  new Promise((resolve) => {
    setTimeout(() => resolve({ data }), 1000);
  });

export { api };

const useApi = () => api;

export default useApi;
