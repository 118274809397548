import React from 'react';
import classNames from 'tailwindcss-classnames';

import { Icon } from 'src/common';
import ProgressBar from './ProgressBar';
import { twMerge } from 'src/lib/mergeTailwind';

const titleSizes = {
  small: 'text-2xl font-bold',
  normal: 'text-3xl font-extrabold',
};

const Card = React.forwardRef(
  (
    {
      icon,
      title,
      titleClassName,
      titleSize = 'normal',
      subtitle,
      children,
      className,
      contentClassName,
      childrenClassName,
      progressBar = null,
      displayEffectiveDate = true,
      ...containerProps
    },
    ref
  ) => {
    titleClassName = twMerge(
      'text-violet',
      titleSizes[titleSize],
      titleClassName
    );

    return (
      <div
        className="bg-white relative rounded-t-2xl shadow-card z-20"
        ref={ref}
        {...containerProps}
      >
        {progressBar && (
          <div className="hidden lg:block mt-8">
            <ProgressBar
              {...progressBar}
              displayEffectiveDate={displayEffectiveDate}
            />
          </div>
        )}
        <div
          className={twMerge(
            'flex flex-col items-center relative px-2 py-8',
            'xs:px-6',
            'lg:p-6',
            className
          )}
        >
          <div
            className={classNames(
              'flex flex-col items-center max-w-xl text-center mb-5',
              contentClassName
            )}
          >
            {icon && <Icon name={icon} size="3xl" className="mb-5" />}
            <h1 className={titleClassName}>{title}</h1>
            {subtitle && (
              <h2 className="mt-2 text-center text-input">{subtitle}</h2>
            )}
          </div>
          <div className={childrenClassName ?? 'w-full sm:px-6'}>
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default Card;
