import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  useApi,
  useModel,
  useAuth0,
  useQueryParams,
  useErrorHandling,
} from 'src/lib/hooks';
import { LOADING_MESSAGE, PAGES } from 'src/products/florida/ho6/constants';
import { LoadingContent, Verify } from 'src/common';
import LoginToContinue from './LoginToContinue';
import InvalidResumption from './InvalidResumption';
import ResumptionError from './ResumptionError';

const Resume = () => {
  const api = useApi();
  const history = useHistory();
  const { user } = useAuth0();
  const { signOut } = useModel.user.dispatch();
  const { id } = useQueryParams();
  const { resume } = useModel.quote.dispatch();
  const [error, setError] = useState(null);
  const [isResuming, setIsResuming] = useState(true);
  const [invalidResumption, setInvalidResumption] = useState(false);
  const [exception, setException] = useState(null);

  const handleError = useErrorHandling();

  const handleResumption = async () => {
    setIsResuming(true);
    try {
      let quoteToResume = null;

      const { data } = await api.secure.get('/profiles');

      const inProgressQuotes = data?.Quotes
        ? Object.entries(data?.Quotes).map(([ID, metadata]) => ({
            ID,
            ...metadata,
          }))
        : [];

      if (id) {
        quoteToResume = inProgressQuotes.find((q) => q.ID === id);
      } else {
        quoteToResume = inProgressQuotes.sort((a, b) => {
          let dateA = dayjs(a.QuoteLastUpdated);
          let dateB = dayjs(b.QuoteLastUpdated);
          if (dateA.isSame(dateB)) {
            return 0;
          }
          return dateA.isAfter(dateB) ? -1 : 1;
        })?.[0];
      }

      if (quoteToResume) {
        if (!!quoteToResume.PolicyNumber) {
          quoteToResume.ResumeFrom = PAGES.CompletionPage;
        }
        await resume(quoteToResume);
        history.replace('/onboarding');
      } else {
        setInvalidResumption(true);
        setIsResuming(false);
      }
    } catch (err) {
      setIsResuming(false);
      if (err.response?.status === 404) {
        setInvalidResumption(true);
        setError(null);
      } else {
        handleError(err).then(setError).catch(setException);
      }
    }
  };

  useEffect(() => {
    if (user?.hasSession) {
      handleResumption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.hasSession]);

  if (exception) {
    throw exception;
  }

  if (invalidResumption) {
    return (
      <InvalidResumption
        {...(user?.hasSession && {
          email: user.email,
        })}
      />
    );
  }

  if (!user?.hasSession && !user?.loggingIn) {
    return <LoginToContinue />;
  } else if (user?.loggingIn) {
    return (
      <Verify onBack={signOut} layoutProps={{ displayNavElements: false }} />
    );
  }

  if (isResuming) {
    return <LoadingContent text={LOADING_MESSAGE.RESUMPTION} />;
  }

  return <ResumptionError error={error} retry={handleResumption} />;
};

export default Resume;
