import React from 'react';
import { BasicLayout, Card, Footer } from 'src/common';

const InternalAgentReferral = ({ handleStartOver }) => {
  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="beachClosed"
        title="Uh oh :("
        subtitle="We’re unable to complete your insurance quote at this time. A Slide Insurance agent will reach out to help you complete and purchase your policy."
      ></Card>
      <Footer>
        <Footer.Next label="Exit" onClick={handleStartOver} icon={null} exit />
      </Footer>
    </BasicLayout>
  );
};

export default InternalAgentReferral;
