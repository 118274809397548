import merge from 'lodash.merge';

const defaultState = {
  page: null,
  pageIndex: 0,
  coveragesPageIndex: 0,
  furthestReached: null,
  furthestIndex: 0,
  skipList: [],
  customerSubmitted: false,
};

const session = {
  state: defaultState,
  reducers: {
    'setSession': (state, session) => ({ ...state, ...session }),
    'quote/resume': (state, { ResumeFrom, FurthestReached, SkipList }) => ({
      ...state,
      page: ResumeFrom,
      furthestReached: FurthestReached,
      skipList: SkipList ?? [],
    }),
    'global/reset': () => defaultState,
  },
  effects: (dispatch) => ({
    updatePage: async (
      { page, pageIndex, skipProfileUpdate = false, waitProfileUpdate = false },
      { quote: { ID }, session: { furthestIndex, skipList }, user }
    ) => {
      const session = { page, pageIndex };
      const body = {
        ResumeFrom: page,
        SkipList: skipList,
      };

      if (furthestIndex < pageIndex) {
        body.FurthestReached = page;
        session.furthestIndex = pageIndex;
      }

      dispatch.session.setSession(session);
    },
    skip: (pages, { session }) => {
      const skipSet = new Set(session?.skipList ?? []);

      Object.entries(pages).forEach(([page, doSkip]) => {
        if (doSkip) {
          skipSet.add(page);
        } else {
          skipSet.delete(page);
        }
      });

      const skipList = Array.from(skipSet);
      dispatch.session.setSession({ skipList });
    },
    setPendingChanges: (pendingChanges) =>
      dispatch.session.setSession({ pendingChanges }),
    stageChanges: (pendingChanges, { session }) =>
      dispatch.session.setSession({
        pendingChanges: merge({}, session?.pendingChanges, pendingChanges),
      }),
  }),
};

export default session;
