export const PROGRESS = {
  EXPLORE_PRICING: {
    totalNotches: 6,
    filledNotches: 1,
    label: 'Explore Our Pricing',
  },
  PROCEED: {
    totalNotches: 6,
    filledNotches: 1,
    label: 'Proceed with Quote',
  },
  INTERVIEW: {
    totalNotches: 6,
    filledNotches: 2,
    label: 'Interview Questions',
  },
  COVERAGES: {
    totalNotches: 6,
    filledNotches: 3,
    label: 'Adjust Coverages',
  },
  DISCOUNTS: {
    totalNotches: 6,
    filledNotches: 4,
    label: 'Apply Discounts',
  },
  FINAL_STEPS: {
    totalNotches: 6,
    filledNotches: 5,
    label: 'Final Steps',
  },
  REVIEW_PAY: {
    totalNotches: 6,
    filledNotches: 6,
    label: 'Review & Pay',
  },
  SUCCESS: {
    totalNotches: 6,
    filledNotches: 6,
    label: 'Success!',
  },
};

export const WIND_MIT = {
  UNIFORM: 'Uniform',
};

export const MISSING_INFO = {
  YEAR_BUILT: 'YearBuilt',
  SQUARE_FEET: 'SquareFeet',
  ROOF_YEAR: 'RoofYear',
  ROOF_MATERIAL: 'RoofMaterial',
  ROOF_SHAPE: 'RoofShape',
  CONSTRUCTION: 'Construction',
  DWELLING_TYPE: 'DwellingType',
};

export const PAGES = {
  CondoStoriesPage: 'CondoStoriesPage',
  CondoUnitsPage: 'CondoUnitsPage',
  YearBuiltPage: 'YearBuiltPage',
  RoofMaterialPage: 'RoofMaterialPage',
  RoofMaterialFollowUpPage: 'RoofMaterialFollowUpPage',
  RoofShapePage: 'RoofShapePage',
  BuildingMaterialPage: 'BuildingMaterialPage',
  InitialQuotePage: 'InitialQuotePage',
  CustomerInformationPage: 'CustomerInformationPage',
  PasswordlessPage: 'PasswordlessPage',
  PreInterviewLoadingPage: 'PreInterviewLoadingPage',
  QuoteCreationPage: 'QuoteCreationPage',
  EffectiveDatePage: 'EffectiveDatePage',
  PreviousCarrierPage: 'PreviousCarrierPage',
  UnderwritingQuestionsPage: 'UnderwritingQuestionsPage',
  OccupancyPage: 'OccupancyPage',
  AdjustCoveragesPage: 'AdjustCoveragesPage',
  CoveragesPage: 'CoveragesPage',
  DeductiblesPage: 'DeductiblesPage',
  OptionalCoveragesPage: 'OptionalCoveragesPage',
  OptionalCoveragesMiscPage: 'OptionalCoveragesMiscPage',
  OptionalCoveragesLimitedLossPage: 'OptionalCoveragesLimitedLossPage',
  OptionalCoveragesPropertyPage: 'OptionalCoveragesPropertyPage',
  DiscountsPage: 'DiscountsPage',
  FormSelectionPage: 'FormSelectionPage',
  UniformUploadPage: 'UniformUploadPage',
  UniformQuestionnairePage: 'UniformQuestionnairePage',
  BuildingUploadPage: 'BuildingUploadPage',
  BuildingQuestionnairePage: 'BuildingQuestionnairePage',
  WindstormUploadPage: 'WindstormUploadPage',
  WindstormQuestionnairePage: 'WindstormQuestionnairePage',
  SavingsPage: 'SavingsPage',
  MortgagePage: 'MortgagePage',
  TrustPage: 'TrustPage',
  AdditionalSelectionPage: 'AdditionalSelectionPage',
  CoApplicantPage: 'CoApplicantPage',
  AdditionalInsuredPage: 'AdditionalInsuredPage',
  MailingAddressPage: 'MailingAddressPage',
  ReviewPage: 'ReviewPage',
  PaymentPage: 'PaymentPage',
  CompletionPage: 'CompletionPage',
  PaperlessPage: 'PaperlessPage',
};

export const ERROR_TYPES = {
  REFERRED_TO_EXT_AGENT: 'Referred to External Agent',
  REFERRED_TO_INT_AGENT: 'Referred to Internal Agent',
  ISSUANCE_FAILURE: 'Issuance Failure',
};

export const COLLECTION_METHOD = {
  RECURRING: 'EFTC',
  ADHOC: 'Paper',
};

export const LOADING_MESSAGE = {
  ACCOUNT_CREATION: 'Thank you! One moment while we get your account set up...',
  RESUMPTION: 'Welcome back! One sec while we access your quote-in-progress...',
  PAYMENT_PREFETCH: 'One moment while we fetch your payment options...',
  PAYMENT_PROCESSING: 'Just a moment while your payment finishes processing.',
};
