import React from 'react';
import './Switch.css';
import { useController } from 'react-hook-form';
import { classnames } from 'tailwindcss-classnames';
import { twMerge } from 'src/lib/mergeTailwind';
const constSize = {
  normal: 'normal',
  large: 'large',
};

const Switch = ({
  control,
  name,
  id,
  hiddenLabel = false,
  className,
  size = 'normal',
  disabled = false,
  showYesNo = true,
}) => {
  const { field } = useController({
    name,
    control,
  });

  const sizeClass = constSize[size];

  return (
    <div className={twMerge('flex flex-row gap-x-2 h-6', className)}>
      {showYesNo && (
        <span
          className={classnames('text-black font-bold my-auto', {
            'sr-only': hiddenLabel,
          })}
        >
          No
        </span>
      )}
      <div className="mt-1">
        <input
          className="react-switch-checkbox"
          id={id ?? name}
          type="checkbox"
          onChange={(event) => {
            field.onChange(event.target.checked); // data send back to hook form
          }} // send value to hook form
          onBlur={field.onBlur} // notify when input is touched/blur
          aria-checked={field.value} // input value
          name={field.name} // send down the input name
          inputRef={field.ref} // send input ref, so we can focus on input when error appear
          value={field.value}
          checked={field.value}
          disabled={disabled}
        />
        <label
          className={classnames('react-switch-label', sizeClass)}
          aria-label={field.name}
          htmlFor={id ?? name}
        >
          <span className={classnames('react-switch-button', sizeClass)} />
        </label>
      </div>
      {showYesNo && (
        <span
          className={classnames('text-black font-bold my-auto', {
            'sr-only': hiddenLabel,
          })}
        >
          Yes
        </span>
      )}
    </div>
  );
};

export default Switch;
